const interviewData = [
  {
    img: "./images/interviewQuestions.webp",
    title: "React Interview Questions",
    desc: "Prepare for your next React interview with curated questions covering essential concepts, best practices, and advanced topics. Gain the confidence to ace your interview!",
    to: "/interview/InterviewQuestions",
  },
  {
    img: "./images/interviewTasks.webp",
    title: "JavaScript/React Interview Tasks",
    desc: "Test your JavaScript/React skills with hands-on tasks designed to simulate real-world challenges. Perfect for honing your practical knowledge and problem-solving abilities.",
    to: "/interview/InterviewTasks",
  },
  {
    img: "./images/interviewQuiz.webp",
    title: "React Interview Quiz",
    desc: "Challenge yourself with a quiz that tests your React knowledge. Covering a range of topics from basics to advanced concepts, this quiz helps you evaluate and enhance your understanding in a fun and interactive way.",
    to: "/interview/InterviewQuiz/slug",
  },
  {
    img: "./images/interviewCodeQuiz.webp",
    title: "React Interview Code Quiz",
    desc: "Challenge your React expertise with an engaging quiz designed to test your ability to identify correct code implementations. Perfect for sharpening your skills and preparing for interviews.",
    to: "/interview/InterviewCodeQuiz",
  },
  {
    img: "./images/interviewCorrectCode.webp",
    title: "React Interview Correct Code",
    desc: "Test your ability to identify the correct React code snippets. This section presents multiple code examples, with one correct solution and others to challenge your understanding of React concepts and best practices.",
    to: "/interview/InterviewCorrectCode/slug"
  }
  
  
];

export { interviewData };
